import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useEffectOnce } from 'react-use';

import { useUserAnalytics } from '../analytics/user';
import { ClockProvider } from '../components/Clock';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import {
  FirebaseContextProvider,
  firebaseService,
  useInitFirebase,
} from '../components/Firebase';
import { FirebaseUtils } from '../components/Firebase/utils';
import { GameCenterContextProvider } from '../components/Game/GameCenter/Context';
import { GameCoverClip } from '../components/GamePackCover/GamePackCoverPres';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { log, useZoomEnv } from '../components/Zoom/utils';
import { ZoomHome } from '../components/Zoom/ZoomHome';
import { ZoomUserRequired } from '../components/Zoom/ZoomUserRequired';
import { useInstance } from '../hooks/useInstance';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  log.info('zoom home client loader entered', { url: action.request.url });
  const productResp = await tokenWithRedirect(
    () => apiService.product.getMostPopularProduct(),
    action.request.url
  );
  const product = productResp.data.product;
  log.info('zoom home client loader done', { product });
  return { product };
};

function Bootstrap() {
  useInitFirebase(true);
  return null;
}

export function Component() {
  const { product } = useLoaderData<typeof clientLoader>();
  const recoveryConfig = useInstance(() => FirebaseUtils.RecoveryConfig());
  const zoomEnv = useZoomEnv();

  useEffectOnce(() => log.info('zoom home component entered'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <FirebaseContextProvider
      svc={firebaseService}
      recoveryConfig={recoveryConfig}
    />,
    <ConfirmCancelModalProvider />,
    <ClockProvider />,
    <ZoomUserRequired />,
    <GameCenterContextProvider />,
  ];
  return (
    <ProvidersList providers={providers}>
      <Bootstrap />
      <ZoomHome env={zoomEnv} product={product} />
      <ConfirmCancelModalRoot />
      <GameCoverClip id='game-cover-clip' />
    </ProvidersList>
  );
}
