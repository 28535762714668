import { Link, useNavigate } from '@remix-run/react';
import {
  Fragment,
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useEffectOnce } from 'react-use';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { useSnapshot } from 'valtio';

import {
  type DtoProduct,
  EnumsPageName,
  EnumsTokenExchangePlatform,
  EnumsZoomLoginMode,
} from '@lp-lib/api-service-client/public';

import { type IZoomClient } from '../../../app/components/Zoom/client';
import { type ZoomEnv } from '../../../app/components/Zoom/utils';
import { useAnalytics } from '../../analytics/AnalyticsContext';
import { ZoomAnalytics } from '../../analytics/zoom';
import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { safeWindowReload } from '../../logger/logger';
import { GamePackContextProvider } from '../../pages/GamePack/Context';
import { EmbeddedPublicLibrary } from '../../pages/GamePack/EmbeddedPublicLibrary';
import { apiService } from '../../services/api-service';
import { type User, type Venue } from '../../types';
import { type GamePack } from '../../types/game';
import { isGuest } from '../../types/user';
import { fromDTOUser } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { getInitials } from '../../utils/string';
import { usePostLogin } from '../Access';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { useOpenGamePackDetailModal } from '../Game/GamePack/GamePackDetail';
import { GamePackPicker } from '../Game/GamePackPicker';
import { GamePackCover } from '../Game/Utilities';
import { HeaderNavLink } from '../Header/Common';
import { type Action, CustomHeaderAvatar } from '../HeaderAvatar';
import { ArrowRightIcon } from '../icons/Arrows';
import { DeleteIcon } from '../icons/DeleteIcon';
import { LeaveIcon } from '../icons/LeaveIcon';
import { LunaParkLogo } from '../icons/LogoIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { VenueIcon } from '../icons/VenueIcon';
import { Loading } from '../Loading';
import { ProvidersList } from '../ProvidersList';
import { useUser } from '../UserContext';
import { log, useZoomClient } from './utils';
import {
  type HostedGame,
  useFreeGames,
  useHostedGames,
  useZoomLobbyAPI,
  useZoomLobbyInited,
  ZoomLobbyProvider,
} from './ZoomLobbyProvider';

function useLoginAction(env: ZoomEnv, client: IZoomClient) {
  const postLogin = usePostLogin();
  const { data: quickLoginAvailable, isLoading } = useSWRImmutable(
    ['/zoom/check-quick-login-available', env.user.id],
    async () => {
      try {
        const resp = await apiService.zoom.checkQuickLoginAvailable(
          env.user.id
        );
        return resp.data.available;
      } catch (error) {
        log.error('Failed to check quick login available', error);
        return false;
      }
    }
  );

  const {
    call: standardLogin,
    state: { state: standardLoginState },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.auth.prepareTokenExchange({
      platform: EnumsTokenExchangePlatform.TokenExchangePlatformZoom,
      platformUid: env.user.id,
    });
    const params = new URLSearchParams();
    params.set(
      'redirect-to',
      `${config.app.baseUrl}/zoom/exchange?code=${resp.data.code}`
    );
    // Do we still need this? It seems like we are not using it.
    params.set('platform', 'zoom');
    client.openUrl(`${config.app.baseUrl}/login?${params.toString()}`);
  });

  const {
    call: quickLogin,
    state: { state: quickLoginState },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.zoom.login({
      zoomUid: env.user.id,
      mode: EnumsZoomLoginMode.ZoomLoginModeManual,
    });
    postLogin({ token: resp.data.token, user: fromDTOUser(resp.data.user) });
  });

  if (isLoading)
    return { login: null, loginState: null, quickLoginAvailable: undefined };

  return quickLoginAvailable
    ? {
        quickLoginAvailable: true,
        login: quickLogin,
        loginState: quickLoginState,
      }
    : {
        quickLoginAvailable: false,
        login: standardLogin,
        loginState: standardLoginState,
      };
}

function Header(props: {
  loggedIn: boolean;
  venueId?: string;
  signUp?: ReactNode;
  login?: ReactNode;
  onLogout?: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const actions: Action[] = [
    {
      key: 'myVenue',
      icon: <VenueIcon />,
      text: 'My Venue',
      onClick: () => {
        if (!props.venueId) return;
        navigate(`/venue/${props.venueId}`);
      },
    },
    {
      key: 'logout',
      icon: <LeaveIcon className='w-3.5 h-3.5 fill-current' />,
      text: 'Logout',
      onClick: () => props.onLogout?.(),
    },
  ];

  return (
    <div className='w-full flex-none h-15 bg-black border-secondary border-b flex items-center z-40 pl-10'>
      <div className='h-full flex-none flex items-center gap-7.5'>
        <div className='flex-none'>
          <Link to='/zoom/home'>
            <LunaParkLogo className='w-27 h-7.5' />
          </Link>
        </div>
      </div>
      <div className='h-full flex-1 flex items-center justify-end gap-7.5 xl:gap-10'>
        {props.loggedIn ? (
          <div className='h-full flex-1 flex items-center justify-end gap-7.5 xl:gap-10'>
            <div className='h-full flex items-center gap-7.5 xl:gap-10'>
              <HeaderNavLink title='Home' to='/zoom/home'></HeaderNavLink>
            </div>
            <div className='mr-3'>
              <CustomHeaderAvatar actions={actions} />
            </div>
          </div>
        ) : (
          <div className='h-full flex-1 flex items-center justify-end gap-2.5 mr-2.5'>
            {props.signUp}
            {props.login}
          </div>
        )}
      </div>
    </div>
  );
}

function ActionButton(props: {
  title: ReactNode;
  subtitle?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  height?: `h-${string}`;
}) {
  return (
    <button
      type='button'
      className={`btn-secondary w-full ${
        props.height ?? 'h-20'
      } relative text-left px-3.5 flex items-center`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.left && <div className='mr-2.5'>{props.left}</div>}
      <div className='flex flex-col gap-1.5 overflow-hidden'>
        {typeof props.title === 'string' ? (
          <div className='font-medium'>{props.title}</div>
        ) : (
          props.title
        )}
        {typeof props.subtitle === 'string' ? (
          <div className='text-icon-gray text-sms truncate'>
            {props.subtitle}
          </div>
        ) : (
          props.subtitle
        )}
      </div>
      {props.right && <div className='ml-auto'>{props.right}</div>}
    </button>
  );
}

function HostedGameActionButton(props: {
  game: HostedGame;
  host: boolean;
  onDelete?: () => void;
  onJoinGame: (game: HostedGame) => void;
}) {
  const { game, host, onJoinGame } = props;

  return (
    <div className='w-full relative group'>
      <ActionButton
        title={
          !host ? (
            <div>
              Join <span className='text-tertiary'>{game.username}'s</span> game
            </div>
          ) : (
            <div>Your hosted game</div>
          )
        }
        subtitle={`Let's play ${game.packName}`}
        left={
          <div className='w-12.5 h-12.5 relative'>
            <div
              className='upper w-full h-full flex items-center justify-center 
            absolute z-0 font-bold bg-black border border-secondary rounded-full'
            >
              {getInitials(game.fullName)}
            </div>
            {game.icon ? (
              <img
                src={game.icon}
                alt='avatar'
                className='w-full h-full object-cover rounded-full absolute'
              />
            ) : null}
          </div>
        }
        right={<ArrowRightIcon className='w-5 h-5 fill-current' />}
        onClick={() => onJoinGame(game)}
      />
      {!!props.onDelete && (
        <button
          type='button'
          className='btn-secondary text-red-002 w-6 h-6 rounded-md hidden 
          group-hover:flex items-center justify-center absolute -right-1 -top-1'
          onClick={props.onDelete}
        >
          <DeleteIcon className='w-2.5 h-2.5 fill-current' />
        </button>
      )}
    </div>
  );
}

function NotInMeeting(props: { launchMeeting: () => void }) {
  return (
    <div className='flex flex-col items-center justify-center'>
      <h1 className='text-2xl font-medium'>Make your Zoom Meetings Fun!</h1>
      <h2 className='text-base font-bold mt-4'>
        Connect your team by adding Ice Breakers, Parlor games and Trivia to
        your Zoom meetings
      </h2>
      <button
        type='button'
        className='btn-primary w-75 h-10 mt-10'
        onClick={props.launchMeeting}
      >
        Open Luna Park in Meeting
      </button>
    </div>
  );
}

type HostGameCallback = (
  venueId: string,
  pack: GamePack,
  features?: Record<string, string>
) => Promise<void>;

function FreeGameActionButton(props: {
  pack: GamePack;
  hostGame: HostGameCallback;
}) {
  const { pack, hostGame } = props;

  const {
    call,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.zoom.setupExperience(pack.id);
    await hostGame(resp.data.venueId, pack, {
      'lobby-switch-game': 'disabled',
    });
  });

  return (
    <div className='w-full relative group'>
      <ActionButton
        title={<div>{pack.name}</div>}
        subtitle={
          <div className='text-icon-gray text-2xs line-clamp-3'>
            {pack.description}
          </div>
        }
        left={
          <div className='flex-shrink-0 w-24'>
            <GamePackCover pack={pack} />
          </div>
        }
        right={
          state.isRunning ? (
            <Loading imgClassName='w-5 h-5' text='' />
          ) : (
            <ArrowRightIcon className='w-5 h-5 fill-current' />
          )
        }
        onClick={() => call()}
        disabled={state.isRunning}
        height='h-24'
      />
    </div>
  );
}

function LegalDisclaimer(props: {
  client: IZoomClient;
  text?: string;
  className?: string;
  downplay?: boolean;
}): JSX.Element {
  return (
    <p
      className={`text-center text-2xs ${
        props.downplay ? 'text-icon-gray' : 'text-white'
      } ${props.className}`}
    >
      {props.text || 'By signing up'}, you agree to our{' '}
      <span
        className={`cursor-pointer ${
          props.downplay ? 'underline' : 'text-tertiary'
        }`}
        onClick={openUrl(props.client, 'https://lunapark.com/terms-of-use/')}
      >
        Terms of Use
      </span>{' '}
      and{' '}
      <span
        className={`cursor-pointer ${
          props.downplay ? 'underline' : 'text-tertiary'
        }`}
        onClick={openUrl(props.client, 'https://lunapark.com/privacy-policy/')}
      >
        Privacy Policy
      </span>
      .
    </p>
  );
}

function SecondaryLinkButton(props: { text: string; onClick?: () => void }) {
  return (
    <div
      className='text-icon-gray text-sms underline cursor-pointer self-center'
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
}

function LearnMoreButton(props: { client: IZoomClient }) {
  return (
    <SecondaryLinkButton
      text=' Learn more about Luna Park'
      onClick={() => {
        props.client.openUrl('https://lunapark.com');
      }}
    />
  );
}

function ZoomLobbyContainer(props: {
  children: ReactNode;
  width?: `w-${string}`;
}) {
  return (
    <div
      className={`mt-12.5 flex flex-col items-center self-center ${
        props.width ?? 'w-full'
      }`}
    >
      <h1 className='text-2xl font-medium'>Welcome to Luna Park</h1>
      {props.children}
    </div>
  );
}

function HostOrJoinPrioritized(props: {
  user: User;
  env: ZoomEnv;
  hostedGames: HostedGame[];
  onHostGame: () => void;
  onJoinHostedGame: (game: HostedGame) => void;
  onDeleteHostedGame: (game: HostedGame) => void;
}) {
  const { user, env, hostedGames } = props;
  return (
    <ZoomLobbyContainer width='w-100'>
      <h2 className='text-base font-bold mt-4'>
        Choose how do you want to join the event
      </h2>
      <div className='w-full mt-10 flex flex-col gap-4'>
        {hostedGames.map((game) => (
          <HostedGameActionButton
            key={game.uid}
            game={game}
            host={game.uid === env.user.id}
            onDelete={
              game.uid === env.user.id
                ? () => props.onDeleteHostedGame(game)
                : undefined
            }
            onJoinGame={props.onJoinHostedGame}
          />
        ))}
        {!!user.organizer && (
          <SecondaryLinkButton
            text='Or Host your Own Game'
            onClick={props.onHostGame}
          />
        )}
      </div>
    </ZoomLobbyContainer>
  );
}

function GuestUserLobby(props: {
  client: IZoomClient;
  hostGame: HostGameCallback;
  signUp: ReactNode;
  login: ReactNode;
}) {
  const packs = useFreeGames();
  return (
    <ZoomLobbyContainer width='w-210'>
      <h2 className='text-base font-bold mt-4'>
        <span>Try one of our </span>
        <span className='text-green-001'>free</span>
        <span> games</span>
      </h2>
      <div className='w-full mt-10 flex flex-col gap-4'>
        <div className='w-full grid grid-cols-2 gap-4 items-center'>
          {packs.map((pack) => (
            <FreeGameActionButton
              key={pack.id}
              pack={pack}
              hostGame={props.hostGame}
            />
          ))}
        </div>
        <LearnMoreButton client={props.client} />
        <div className='w-full h-80 bg-modal rounded-2.25xl mt-4 relative overflow-hidden'>
          <div className='w-[60%] p-12 absolute flex flex-col gap-7.5'>
            <h1
              className='text-3.5xl font-bold'
              style={{
                lineHeight: 'normal',
                letterSpacing: '0.25px',
              }}
            >
              Sign Up or Login <br />
              to Access <br />
              <span className='text-red-002'>Hundreds</span> of Experiences
            </h1>
            <div className='w-full flex items-center gap-2.5'>
              {props.signUp}
              {props.login}
            </div>
            <LegalDisclaimer
              client={props.client}
              text='By joining Luna Park'
              className='self-start'
              downplay
            />
          </div>
          <img
            className='h-full right-0 absolute'
            src={getStaticAssetPath('images/zoom-lobby-login-bg.png')}
            alt='games'
          />
        </div>
      </div>
    </ZoomLobbyContainer>
  );
}

function StartGameDetailAction(props: { onClick?: () => void }) {
  return (
    <div className='mt-28 w-full'>
      <button
        type='button'
        className='btn-delete w-full h-15 rounded flex items-center justify-center gap-2 text-white'
        onClick={props.onClick}
      >
        <PlayIcon />
        <p>Start Game</p>
      </button>
    </div>
  );
}

function MemberUserLobby(props: {
  onHostGame: (pack: GamePack) => Promise<void>;
}) {
  const openPackDetailModal = useOpenGamePackDetailModal();
  return (
    <div className='w-full flex flex-col items-center self-center'>
      <GamePackContextProvider
        embed={true}
        pageType='public'
        noOnboardingTasks
        noPlayButton
      >
        <EmbeddedPublicLibrary
          pageName={EnumsPageName.PageNameZoom}
          onGamePackClick={(pack) =>
            openPackDetailModal(
              pack,
              <StartGameDetailAction onClick={() => props.onHostGame(pack)} />
            )
          }
        />
      </GamePackContextProvider>
    </div>
  );
}

function ZoomLobby(props: {
  client: IZoomClient;
  env: ZoomEnv;
  venue: Venue | undefined;
  signUp: ReactNode;
  login: ReactNode;
  mock: boolean;
}) {
  const { client, env, venue, mock } = props;
  const navigate = useNavigate();
  const user = useUser();
  const zoomLobbyAPI = useZoomLobbyAPI();
  const hostedGames = useHostedGames();
  const inited = useZoomLobbyInited();
  const analytics = useAnalytics();
  const zoomAnalytics = useMemo(
    () => new ZoomAnalytics(analytics),
    [analytics]
  );

  const openPackDetailModal = useOpenGamePackDetailModal();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const hostGame = async (
    venueId: string,
    pack: GamePack,
    features?: Record<string, string>
  ) => {
    const params = new URLSearchParams();
    params.set('featured-game', pack.id);
    params.set('guest-users', 'enabled');
    if (mock) {
      params.set('zoom-mock-env', 'enabled');
    }
    if (features) {
      for (const [key, value] of Object.entries(features)) {
        params.set(key, value);
      }
    }
    const path = `/venue/${venueId}?${params.toString()}`;
    await zoomLobbyAPI.addHostedGame({
      uid: env.user.id,
      fullName: `${env.user.first_name} ${env.user.last_name}`,
      username: env.user.first_name || env.user.display_name,
      icon: env.user.pic_url,
      packName: pack.name,
      packId: pack.id,
      url: path,
    });
    zoomAnalytics.trackZoomHostGameClicked({
      packId: pack.id,
      packName: pack.name,
      venuePath: path,
    });
    await client.startCollaborate();
    navigate(path);
  };

  const openGamePackPicker = async () => {
    if (!user.organizer) return;
    await confirmCancel({
      kind: 'custom',
      containerClassName: 'bg-none',
      element: ({ internalOnCancel, internalOnConfirm }) => (
        <div className='fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center'>
          <div
            className={`w-3/4 border border-secondary rounded-xl px-20 py-12 bg-modal`}
          >
            <GamePackPicker
              pageName={EnumsPageName.PageNameZoom}
              onCancel={internalOnCancel}
              onSelect={(pack) => {
                openPackDetailModal(
                  pack,
                  <StartGameDetailAction
                    onClick={async () => {
                      if (!venue) return;
                      await hostGame(venue.id, pack);
                      internalOnConfirm();
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
      ),
    });
  };

  const onJoinHostedGame = (game: HostedGame) => {
    zoomAnalytics.trackZoomHostGameClicked({
      packId: game.packId,
      packName: game.packName,
      venuePath: game.url,
    });
    navigate(game.url);
  };

  if (!inited) {
    return (
      <ZoomLobbyContainer>
        <Loading containerClassName='mt-4' />
      </ZoomLobbyContainer>
    );
  }

  if (hostedGames.length > 0) {
    return (
      <HostOrJoinPrioritized
        {...props}
        user={user}
        hostedGames={hostedGames}
        onHostGame={openGamePackPicker}
        onJoinHostedGame={onJoinHostedGame}
        onDeleteHostedGame={(game) => zoomLobbyAPI.deleteHostedGame(game.uid)}
      />
    );
  }

  if (isGuest(user)) {
    return (
      <GuestUserLobby
        client={client}
        hostGame={hostGame}
        signUp={props.signUp}
        login={props.login}
      />
    );
  }

  return (
    <MemberUserLobby
      onHostGame={async (pack) => {
        if (!venue) return;
        await hostGame(venue.id, pack);
      }}
    />
  );
}

function openUrl(client: IZoomClient, url: string) {
  return () => {
    client.openUrl(url);
  };
}

function LoginButton(props: {
  env: ZoomEnv;
  client: IZoomClient;
  width?: `w-${string}`;
  showLegalDisclaimer?: boolean;
}) {
  const { env, client } = props;
  const { login, loginState, quickLoginAvailable } = useLoginAction(
    env,
    client
  );

  if (quickLoginAvailable === undefined) {
    return (
      <div
        className={`${
          props.width ?? 'w-full'
        } h-10 flex items-center justify-center`}
      >
        <Loading text='' />
      </div>
    );
  }

  if (quickLoginAvailable) {
    return (
      <div className={`relative group ${props.width ?? 'w-full'}`}>
        <button
          type='button'
          className={`btn-secondary w-full h-10 font-medium flex items-center justify-center gap-2`}
          onClick={login}
          disabled={loginState.isRunning}
        >
          {loginState.isRunning ? <Loading text='' /> : <div>Quick Login</div>}
        </button>
        {props.showLegalDisclaimer && (
          <div className='absolute hidden group-hover:block right-0'>
            <LegalDisclaimer
              client={client}
              className='w-50 mt-2 !text-3xs bg-black rounded px-2 py-1 border-secondary border'
              text='By clicking "Quick Login"'
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <button
      type='button'
      className={`btn-secondary ${
        props.width ?? 'w-full'
      } h-10 font-medium flex items-center justify-center gap-2`}
      onClick={login}
      disabled={loginState.isRunning}
    >
      {loginState.isRunning && <Loading text='' />}
      <div>Login</div>
    </button>
  );
}

function SignupButton(props: {
  env: ZoomEnv;
  client: IZoomClient;
  product: Nullable<DtoProduct>;
  width?: `w-${string}`;
}) {
  const { env, client, product } = props;

  const {
    call: signUp,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const params = new URLSearchParams();
    params.set('register-for', 'subscription');
    params.set('email', env.user.email);
    params.set('first-name', env.user.first_name);
    params.set('last-name', env.user.last_name);
    params.set('trial', 'true');

    if (!env.mock) {
      const resp = await apiService.auth.prepareTokenExchange({
        platform: EnumsTokenExchangePlatform.TokenExchangePlatformZoom,
        platformUid: env.user.id,
      });
      params.set(
        'redirect-to',
        `${config.app.baseUrl}/zoom/exchange?code=${resp.data.code}`
      );
    }

    if (product) {
      params.set('product-id', product.id);
    }
    client.openUrl(`${config.app.baseUrl}/register?${params.toString()}`);
  });

  return (
    <button
      type='button'
      className={`btn-success ${props.width ?? 'w-full'} h-10 font-medium`}
      onClick={signUp}
      disabled={state.isRunning}
    >
      Sign Up for Trial
    </button>
  );
}

function ZoomHomeWithEnv(props: {
  env: ZoomEnv;
  product: Nullable<DtoProduct>;
}) {
  const { env, product } = props;
  const zoomMockEnabled = useFeatureQueryParam('zoom-mock-env');
  const client = useZoomClient(zoomMockEnabled);
  const clientState = useSnapshot(client.state);
  const meetingId = clientState.meetingId;
  const runningContext = clientState.runningContext;
  const user = useUser();
  const { data: venue } = useSWR(
    !!user.organizer ? '/my/venue' : null,
    async () => {
      if (isGuest(user)) return;
      const resp = await apiService.venue.getMyVenue();
      return resp.data.venue;
    }
  );

  useLayoutEffect(() => {
    log.info('zoom home with env entered');
    client.init();
  }, [client]);

  useEffect(() => {
    if (runningContext !== 'inMeeting') return;
    client.expandApp('expand');
  }, [client, runningContext]);

  const logout = async () => {
    const resp = await apiService.zoom.logout({ zoomUid: env.user.id });
    localStorage.setItem('token', resp.data.token);
    safeWindowReload();
  };

  return (
    <div
      className={`w-screen h-screen flex flex-col text-white ${
        clientState.inited ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
    >
      <Header
        loggedIn={!!user.organizer}
        venueId={venue?.id}
        signUp={
          <SignupButton
            env={env}
            client={client}
            product={product}
            width='w-51'
          />
        }
        login={
          <LoginButton
            env={env}
            client={client}
            width='w-35'
            showLegalDisclaimer
          />
        }
        onLogout={logout}
      />
      <div
        className={`relative w-full h-full flex flex-col overflow-auto 
          scrollbar bg-library-2023-07 ${
            !meetingId ? 'items-center justify-center' : ''
          }`}
      >
        {clientState.inited && (
          <Fragment>
            {!meetingId ? (
              <NotInMeeting
                launchMeeting={() => {
                  client.launchAppInMeeting();
                }}
              />
            ) : (
              <ProvidersList
                providers={[<ZoomLobbyProvider meetingId={meetingId} />]}
              >
                <ZoomLobby
                  client={client}
                  env={env}
                  venue={venue}
                  mock={zoomMockEnabled}
                  signUp={
                    <SignupButton
                      env={env}
                      client={client}
                      product={product}
                      width='w-1/2'
                    />
                  }
                  login={
                    <LoginButton env={env} client={client} width='w-1/2' />
                  }
                />
              </ProvidersList>
            )}
          </Fragment>
        )}
      </div>
      <div className='absolute bottom-1 text-3xs left-1/2 tramsform -translate-x-1/2'>
        <span
          className='hover:underline cursor-pointer mx-1'
          onClick={openUrl(client, 'https://lunapark.com/terms-of-use/')}
        >
          Terms
        </span>
        <span>|</span>
        <span
          className='hover:underline cursor-pointer mx-1'
          onClick={openUrl(client, 'https://lunapark.com/privacy-policy/')}
        >
          Privacy
        </span>
      </div>
    </div>
  );
}

function ZoomHomeWithoutEnv() {
  useEffectOnce(() => log.info('zoom home without env entered'));
  const onClick = () => {
    safeWindowReload();
  };
  return (
    <div className='w-screen h-screen flex flex-col text-white'>
      <Header loggedIn={false} />
      <div
        className={`relative w-full h-full flex flex-col overflow-auto 
          scrollbar bg-library-2023-07 items-center justify-center`}
      >
        <h1 className='text-2xl font-medium'>Something is Wrong!</h1>
        <h2 className='text-base font-bold mt-4'>
          It seems the Zoom App is not initalized correctly.
        </h2>

        <button
          type='button'
          className='btn-primary w-75 h-10 flex items-center justify-center mt-10'
          onClick={onClick}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}

export function ZoomHome(props: {
  env: ZoomEnv | undefined;
  product: Nullable<DtoProduct>;
}) {
  if (props.env) {
    return <ZoomHomeWithEnv env={props.env} product={props.product} />;
  }
  return <ZoomHomeWithoutEnv />;
}
